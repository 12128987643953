// 查询列表
const queryListURL = `${API_CONFIG.controlBaseURL}workFlowInfo/pageList`
// 创建人 select2
const getUserListURL = `${API_CONFIG.controlBaseURL}manager/user/search/keyword`

// 新增基本信息
const createBaseInfoURL = `${API_CONFIG.controlBaseURL}workFlowInfo/info/add`
// 更新基本信息
const updateBaseInfoURL = `${API_CONFIG.controlBaseURL}workFlowInfo/info/update`
// 查询详情
const queryDetailURL = `${API_CONFIG.controlBaseURL}workFlowInfo/base/info`

// 保存流程
const saveDesignURL = `${API_CONFIG.controlBaseURL}process/model/design`
// 发布流程
const deployDesignURL = `${API_CONFIG.controlBaseURL}workFlowInfo/info/deploy`

export {
  queryListURL,
  getUserListURL,
  createBaseInfoURL,
  updateBaseInfoURL,
  queryDetailURL,
  saveDesignURL,
  deployDesignURL
}
