var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "WorkFlowForm" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "流程名称",
                        rules: [
                          {
                            required: true,
                            message: "请输入流程名称",
                            trigger: "change",
                          },
                        ],
                        prop: "flowName",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "最多填写20个汉字",
                          maxlength: 20,
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.flowName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "flowName", $$v)
                          },
                          expression: "form.flowName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "流程类型",
                        rules: [
                          {
                            required: true,
                            message: "请选择流程类型",
                            trigger: "change",
                          },
                        ],
                        prop: "flowType",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          options: _vm.flowTypeOps,
                          placeholder: "请选择流程类型",
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.flowType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "flowType", $$v)
                          },
                          expression: "form.flowType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "流程描述",
                        rules: [
                          {
                            required: true,
                            message: "请输入流程描述",
                            trigger: "change",
                          },
                        ],
                        prop: "flowDescribe",
                      },
                    },
                    [
                      _c("v-textarea", {
                        attrs: {
                          placeholder: "请输入流程描述",
                          maxlength: 150,
                        },
                        model: {
                          value: _vm.form.flowDescribe,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "flowDescribe", $$v)
                          },
                          expression: "form.flowDescribe",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.flowKey
                    ? _c("el-form-item", { attrs: { label: "审批流编号" } }, [
                        _c("div", [_vm._v(_vm._s(_vm.flowKey))]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }