<template>
  <div class="WorkFlowForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail>

        <col2-block>

          <el-form-item label="流程名称" :rules="[{ required: true, message: '请输入流程名称', trigger: 'change'}]" prop="flowName">
            <v-input v-model="form.flowName" placeholder="最多填写20个汉字" :maxlength="20" :width="width" />
          </el-form-item>

          <el-form-item label="流程类型" :rules="[{ required: true, message: '请选择流程类型', trigger: 'change'}]" prop="flowType">
            <v-select v-model="form.flowType" :options="flowTypeOps" placeholder="请选择流程类型" :width="width" />
          </el-form-item>

          <el-form-item label="流程描述" :rules="[{ required: true, message: '请输入流程描述', trigger: 'change' }]" prop="flowDescribe">
            <v-textarea v-model="form.flowDescribe" placeholder="请输入流程描述" :maxlength="150" />
          </el-form-item>

          <el-form-item v-if="flowKey" label="审批流编号">
            <div>{{ flowKey }}</div>
          </el-form-item>

        </col2-block>

      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { queryDetailURL, createBaseInfoURL, updateBaseInfoURL } from './api'
import { setFlowTypeOps } from './map'
import { Col2Detail, Col2Block } from 'components/bussiness'

export default {
  name: 'WorkFlowForm',
  components: {
    Col2Detail,
    Col2Block
  },
  data () {
    return {
      width: 200,
      flowTypeOps: setFlowTypeOps(),
      flowKey: '',
      form: {
        id: undefined,
        flowName: '',
        flowType: 1,
        flowDescribe: ''
      },
      submitConfig: {
        submitUrl: '',
        submitMethod: 'POST',
        queryUrl: queryDetailURL
      }
    }
  },
  mounted () {
    const { id } = this.$route.query
    if (id !== undefined) {
      this.$setBreadcrumb('编辑流程')
      this.submitConfig.submitUrl = updateBaseInfoURL
      this.$refs.formPanel.getData({
        id
      })
    } else {
      this.$setBreadcrumb('新增流程')
      this.submitConfig.submitUrl = createBaseInfoURL
    }
  },
  methods: {
    update (data) {
      Object.keys(this.form).forEach(key => {
        if (data[key] !== undefined) {
          this.form[key] = data[key]
        }
      })
      this.flowKey = data.flowKey
    },
    submitBefore (data) {
      return true
    }
  }
}
</script>
